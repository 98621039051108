import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"cookies"} />
		<Helmet>
			<title>
				Sikeres Kommunikáció - Sziklay Bence
			</title>
			<meta name={"description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:title"} content={"Sikeres Kommunikáció - Sziklay Bence"} />
			<meta property={"og:description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/mesterkulcs.webp?v=2024-03-07T19:53:21.189Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
		<Components.GlobalHeaderNavBar />
		<Section>
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" font="--headline1">
					Sütik
				</Text>
			</Box>
			<Components.Cookies margin="30px 0px 0px 0px" />
		</Section>
		<Components.FooterGlobal>
			<Override slot="text1" />
			<Override slot="CreatorSignature" />
		</Components.FooterGlobal>
	</Theme>;
});